@media print {
    @page {
        size: landscape;
    }

    .overflow-x-auto {
        overflow: hidden !important;
    }

    .print-row {
        flex-direction: row !important;
    }

    .header,
    .no-print,
    .side-nav,
    .footer {
        display: none !important;
    }

    .border-l {
        border: 0 !important;
    }
}

.logo-frame {
    margin: 20px 0 10px;
    padding-right: 0;
    width: 75px !important;
    height: 22px !important;
    transition: all 0.5 ease-in-out;

    &.--h-31px {
        margin: 0px 0px 10px 5px;
        width: 65px !important;
        height: 25px !important;
    }

    &.--collapsed {
        width: 65px !important;
        height: 45px !important;
        // margin: 0px 0px 0px 5px;
        // width: 65px !important;
        // height: 25px !important;
    }

    &.--jobsheet {
        margin: 0;
        height: 50px !important;
    }
}

.welcome-frame {
    width: auto;
    height: 240px;
    margin-bottom: -2em;
}

.image-frame {
    width: 100%;
    object-fit: cover;

    img {
        object-fit: cover;
    }
}

.side-nav-header {
    display: flex;
    gap: 0.8em;
    align-items: baseline;

    h2 {
        font-style: italic;
        font-size: 28px;
    }
}

.upload-frame {
    height: 60px;
}

.filter-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75em;
    padding: 1em 1.5em 0;
    overflow-y: auto;
    margin-bottom: 1.5em;
}

.filter-tag {
    cursor: pointer;
    height: max-content;
    width: max-content;
    min-width: 90px;
    padding: 0.3em 1em;
    // background: #f0f0f021;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.8em;
    border: 1px solid #dfdfdf;

    p {
        font-size: 13px;
    }

    svg {
        // margin-top: 0.2em;
        color: #c3c2c2;
        width: 18px !important;
        height: 18px !important;
        transition: color 0.2s ease;
    }

    &:hover {
        border: 1px solid #c4c4c4;

        svg {
            color: #a1a1a1;
        }
    }
}

.clear-all {
    padding: 0 0.5em;
    border-bottom: 1px solid #e5e7eb;

    p {
        font-size: 13px;
        font-weight: 500;
    }

    hr {
        margin: 0.5em 0 0;
        border-color: #27282f;
    }
}

.accordion-collapse {
    &.show {
        visibility: visible !important;
    }
}

.collapse:not(.show) {
    display: none;
}

.accordion-item {
    border-bottom: 1px solid #e5e7eb;

    button {
        width: 100% !important;
        padding: 1em 1.6em 0.8em;
        letter-spacing: 0.01em;
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        color: #111827;
        font-size: 16px;
        font-weight: 500;
    }
}

.accordion-body {
    display: flex;
    padding: 0rem 2em 1.5em !important;

    p {
        font-size: 15px;
        color: var(--primary_font_color);
    }

    &.--brand {
        flex-direction: column;
        gap: 1em;
        overflow-y: auto;
        height: 300px;
        margin-bottom: 1.5em;
    }

    &.--model {
        gap: 1em;
        flex-wrap: wrap;
        max-height: 200px;
        overflow-y: auto;
        margin-bottom: 1.5em;
        padding-bottom: 0 !important;

        .model-item {
            cursor: pointer;
            padding: 0.8em 1.8em;
            background: #f3f4f6;
            width: fit-content;
            border: 0 !important;

            p {
                color: #6b7280;
                font-size: 14px;
                font-weight: unset !important;
            }

            &.--selected {
                // border: 1px solid #d7d7d7;
                background: #ff979721;

                p {
                    color: #dc2626;
                    font-family: var(--secondary_font);
                }
            }
        }
    }

    &.--year {
        flex-direction: column;
        gap: 2em;
    }

    &.--model-empty {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px;

        p {
            font-size: 13px;
            color: #6b72805c;
        }
    }
}

.accordion-flush {
    --mdb-accordion-flush-btn-box-shadow: unset !important;
    --mdb-accordion-flush-border-bottom: 1px solid #353641 !important;
}

.accordion-button::after {
    content: ''; /* Ensure content is specified to make ::after visible */
    flex-shrink: 0;
    width: 0.8rem;
    height: 0.8rem;
    margin-left: auto;
    background-repeat: no-repeat;
    background-size: 0.8rem;
    transition: transform 0.2s ease-in-out;
    background-image: url('../assets/image/filter-dropdown.svg');
    display: block;
}

.accordion-button:not(.collapsed)::after {
    transform: rotate(-180deg);
    background-image: url('../assets/image/filter-dropdown.svg'); /* Active state image */
}

.range-slider {
    height: 6px !important;
    border-radius: 2px;
}

.range-slider__range {
    background: #dc2626 !important;
}

.range-slider__thumb {
    width: 18px !important;
    height: 18px !important;
    background: #ffffff !important;
    border: 3px solid #dc2626 !important;
}

.year-input {
    gap: 1em;
    display: flex;
    align-items: end;
    width: 100%;

    span {
        margin-bottom: 0.8em;
    }

    p {
        margin-bottom: 0.25em;
        font-size: 13px;
    }

    input {
        font-size: 15px;
    }
}

.table-subtitle {
    font-size: 15px;
}

.flex-column {
    flex-direction: column;
}

.row-gap-0 {
    row-gap: 0 !important;
}

.border-left-0 {
    border-left: 0 !important;
}

.minw-25 {
    min-width: 25em;
}

.mb-45 {
    margin-bottom: 0.45rem;
}

.whitespace-pre {
    white-space: pre;
}

.pointer {
    cursor: pointer;
}

.w-48 {
    width: 48%;
}

.w-85 {
    width: 85%;
}

.w-100 {
    width: 100%;
}

.min-w-44 {
    min-width: 44px;
}

.form-explain {
    position: relative;
    bottom: 0 !important;
    margin-top: 0.5em !important;
}

.form-item {
    margin-bottom: 1.5em;

    &.--unset {
        margin-bottom: 0 !important;
    }
}

.dialog-overlay {
    display: flex;
    align-items: center;
}

.dialog-after-open {
    @media (max-width: 650px) {
        margin: 0;
        width: 100% !important;
    }
}

.dialog-content {
    height: max-content;
    overflow: auto;

    @media (max-width: 1024px) {
        height: auto;
        max-height: 88vh;
    }

    @media (max-width: 650px) {
        height: 100vh;
        border-radius: 0;
        max-height: unset !important;
    }

    &::-webkit-scrollbar {
        display: none !important;
    }
}

.highlight-to-show {
    // display: -webkit-inline-box;
    // -webkit-line-clamp: 2;
    // -webkit-box-orient: vertical;
    // overflow: hidden;
    // white-space: normal;
    // text-overflow: ellipsis;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.icon-frame {
    path {
        stroke: #4b5563;
    }
}

.tab-nav-active {
    background: #ffefef;
}

.tab-nav {
    padding: 0;

    @media (min-width: 991px) {
        min-width: 100px;
    }
}

.tab-frame {
    height: 100%;
    width: 100%;
    padding: 0.75rem 1.25rem;
    text-align: center;
    white-space: pre;
}

.search-product {
    max-height: 600px;
    height: 100%;
    // position: relative;
    // top: 0;
    transition: max-height 0.5s ease-in-out;

    &.--shrink {
        // position: absolute;
        max-height: 0px;
        // height: 0px;
    }
}

.card-header {
    padding: 0;
    width: 100%;
    height: 100px;
    object-fit: cover;
}

.cart-item {
    color: #000000;
    font-weight: 600;
    font-size: 15px;

    span {
        color: #6b7280;
        font-weight: 400;
        font-size: 14px;
    }

    &.--price {
        font-size: 15px;
        font-weight: 600;
    }
}

.input-qty {
    border-radius: 5px;

    input {
        min-height: 33px;
        cursor: default;
        width: 2.8em;
        border-color: #dc2626 !important;
        // border: 0 !important;
    }

    button {
        min-height: 33px;
        width: 1.8em;
    }

    &.--order {
        input {
            width: 3.2em;
            border-color: #dc2626 !important;
            // border: 0 !important;
        }
    }
}

.option-label {
    color: #000000;
    font-weight: 500;
    text-transform: capitalize;
}

.sticky-bottom {
    background: #ffffff;
    position: sticky;
    bottom: 0;
    padding: 1em 1.5em;
    // box-shadow: 0 -1px 20px 0px #f5f5f5;
}

.sticky-top {
    background: #ffffff;
    position: sticky;
    top: 0;
    padding-bottom: 1em;
    // box-shadow: 0 -1px 20px 0px #f5f5f5;
}

.datatable-upload {
    button {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
}

.datatable-file {
    align-items: center;
    border-color: #d1d5db;
    border-radius: 0.5rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-width: 1px;
    display: flex;
    justify-content: space-between;

    .upload-file-info {
        text-align: start;
        min-width: 72px;
        min-height: 42px;
        padding: 0 !important;
    }

    h6 {
        word-break: break-all;
        font-size: 12px !important;
        display: -webkit-inline-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
    }

    span {
        font-size: 11px;
    }
}

.pencil-input {
    .input {
        height: auto;
        border: 0;
        border-bottom: 1px solid #d1d5db;
        border-radius: 0;
        padding: 0.25rem 0.5rem;
        width: 11em !important;
        text-align: center;

        &:focus {
            border-color: #dc2626;
            --tw-ring-opacity: 0 !important;
        }
    }

    &.--remark {
        height: auto;
        border: 0;
        border-bottom: 1px solid #d1d5db;
        border-radius: 0;
        padding: 0.25rem 0.5rem;
        width: 11em !important;

        &:focus {
            border-color: #dc2626;
            --tw-ring-opacity: 0 !important;
        }
    }

    .input-suffix-end {
        display: none;
    }
}

.export-as {
    color: #6b7280;
    background: #f5f5f5;
}

.export-dropdown {
    padding: 0;
    margin-top: 0.5em;
    width: 100% !important;
    min-width: unset !important;

    .menu-item {
        font-weight: normal;
        height: 38px !important;

        &:hover {
            border-radius: 0 !important;
        }
    }
}

.pre {
    white-space: pre;
}

.m-0-fi {
    margin: 0 !important;
}

.min-h-65 {
    min-height: 65vh;
}

.text-center {
    text-align: center !important;
}

.overflow-unset {
    overflow: unset;
}

.service-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e2e4e7;
    min-width: 8em;

    &.--selected {
        border: 1px solid #ff6c6c4d;
        box-shadow: 3px 3px 4px 0 #ff6c6c4d;
        color: #dc2626;
    }

    @media (max-width: 1200px) {
        min-width: unset;
    }

    @media (max-width: 991px) {
        min-width: unset;
        width: 100%;
    }
}

.add-product {
    border: 2px dashed #e5e7eb;
    background: transparent;
    max-height: 70px;

    &:hover {
        border-color: #dc2626a9;

        .icon-bg {
            background: #fee2e2;

            svg {
                color: #dc2626;
            }
        }
    }
}

.one-line {
    display: -webkit-inline-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
}

.two-line {
    display: -webkit-inline-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;

    &.--break-word {
        display: -webkit-inline-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-word;
        text-overflow: ellipsis;
    }
}

.upload-file-thumbnail {
    min-width: 66px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.minw-25 {
    min-width: 25em;

    @media screen and (max-width: 400px) {
        width: 100%;
        min-width: unset;
    }
}

.minw-75 {
    min-width: 51.5em;

    @media screen and (max-width: 1080px) {
        width: 80%;
        min-width: unset;
    }

    @media screen and (max-width: 400px) {
        width: 100%;
        min-width: unset;
    }
}

.object-cover {
    object-fit: cover;
}

.link-color {
    color: #5052cf !important;
}

.no-data {
    padding: 4em 0 !important;
    text-align: center;

    &:hover {
        background: transparent !important;
    }
}

.add-product-dialog {
    width: 100% !important;
    max-width: 95% !important;

    @media (max-width: 500px) {
        max-width: unset !important;
    }

    .dialog-content {
        height: 92.5vh;

        @media (max-width: 500px) {
            height: 100vh !important;
            padding: 0;
            .close-btn {
                margin-top: 1em !important;
            }
        }

        .close-btn {
            margin-top: -0.5em;
        }
    }
}

.upload-file-thumbnail {
    position: relative !important;
}

.upload-file-image {
    min-width: 44px;
    min-height: 40px;
}

.upload-file-preview {
    position: absolute !important;
    top: 0 !important;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s;
    background: #00000080;

    &.--full {
        cursor: pointer;
        opacity: 1;
        width: 45px;
        height: 45px;
        z-index: 15;
        top: unset !important;
        background: #ebebebe3;
        backdrop-filter: blur(3px);
        border-radius: 100%;
        margin: 0.5em;

        svg {
            color: #ffffff !important;
            width: 1.2rem;
            height: 1.2rem;
        }
    }

    &:hover {
        opacity: 1;
    }

    svg {
        color: #ffffff;
        width: 1.7rem;
        height: 1.7rem;
    }
}

.file-preview {
    // width: 100%;
    display: grid;
    place-items: center;
    min-width: 472px;
    min-height: 500px;
    max-height: 500px;
    max-width: 472px;
    overflow-y: scroll;
    border-radius: 10px;
    padding: 0 0em;
    border: 1px solid #ddd;
    user-select: none !important;

    @media (max-width: 480px) {
        width: 100%;
        min-width: unset;
        max-width: unset;
        min-height: 400px;
        max-height: 400px;
    }

    &.--full {
        padding-top: 1em;
        min-height: unset;
        min-width: unset;
        max-height: unset;
        max-width: unset;
        height: 100vh;
        width: 100%;
        place-content: center;
        overflow: hidden;
        // display: grid;
        border: 0;
        border-radius: unset;

        .video-container {
            height: 100% !important;
        }

        .photo-viewer-container {
            width: 100% !important;
            height: 100% !important;

            img {
                width: unset !important;
                height: unset !important;
            }
        }

        .pg-viewer-wrapper {
            width: 100%;
            height: 100%;
            // min-width: 100%;
            min-height: 100vh;
            min-width: 100vw !important;
            max-width: unset;
            max-height: unset;
            border-radius: 0;
            overflow-x: hidden;

            @media (max-width: 800px) {
                min-width: 400px;
            }

            .pg-viewer {
                width: 100%;
            }

            .pdf-canvas {
                place-content: center;
                display: grid;

                @media (max-width: 800px) {
                    // width: 400px;
                    padding-top: 4em;
                }
            }

            .loading-container {
                .loading {
                    margin-top: 12em;
                }
            }

            &::-webkit-scrollbar {
                display: none !important;
            }
        }
    }

    iframe {
        width: 100%;
        height: 500px;
        overflow: hidden !important;

        img {
            width: 100% !important;
            height: 100% !important;
            object-fit: cover !important;
        }

        &::-webkit-scrollbar {
            display: none !important;
        }
    }

    .p-image-show {
        height: 500px !important;

        img {
            object-fit: contain !important;
        }
    }

    .video-container {
        height: 500px !important;

        video {
            height: 100%;
        }
    }

    .pg-viewer-wrapper {
        max-width: 470px;
        min-width: 470px;
        border-radius: 10px;

        @media (max-width: 480px) {
            min-width: 400px;
            max-width: 400px;
        }

        .pg-viewer {
            width: 100%;
        }

        .pdf-canvas {
            display: grid;
            place-content: center;
        }

        .loading-container {
            .loading {
                margin-top: 12em;
            }
        }

        &::-webkit-scrollbar {
            display: none !important;
        }
    }

    .photo-viewer-container {
        width: 100% !important;
        height: 500px !important;

        img {
            width: 100% !important;
            height: 100% !important;
            object-fit: contain;
        }
    }

    .react-grid-Container {
        border-radius: 10px;
    }

    .react-grid-Grid {
        height: 200px !important;
        border-radius: 10px;
        border: 0;
    }

    .react-grid-Canvas,
    .react-grid-Viewport {
        height: 100% !important;

        &::-webkit-scrollbar {
            display: none !important;
        }
    }

    &::-webkit-scrollbar {
        display: none !important;
    }
}

.social-icon {
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    display: grid;
    place-items: center;

    &.--green {
        background: #e0f2e2;
    }

    &.--blue {
        background: #dae6f4;
    }

    &.--beige {
        background: #fef7e7;
    }
}

.preview-swiper {
    .swiper-wrapper {
        display: flex;
    }
}

.preview-swiper-prev {
    left: 0;
    margin-left: 0.8em;
}

.preview-swiper-next {
    right: 0;
    margin-right: 0.8em;
}

.preview-swiper-prev,
.preview-swiper-next {
    top: 43%;
    position: absolute;
    z-index: 5;
    background: #fff;
    border-radius: 100%;
    border: 1px solid #ddd;
    cursor: pointer;
}

.p-image-toolbar {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    padding: 1rem;
}

.p-image-action {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.pg-viewer-wrapper {
    &::-webkit-scrollbar {
        display: none;
    }
}

.rich-text-editor {
    &.--disabled {
        background-color: #f3f4f6;
        color: #9ca3af;
    }
}

.upload {
    &.disabled {
        opacity: 1;
        background-color: #f3f4f6;

        div {
            opacity: 0.4;
        }
    }
}

.disabled-img {
    background: #f3f4f6;

    img {
        opacity: 0.5;
    }
}
